import React from 'react';

import DOMPurify from 'isomorphic-dompurify';
import { useRouter } from 'next/router';

import useTranslateLpc from '@components/common/hooks/useTranslateLpc';
import useWindowSize from '@components/common/hooks/useWindowSize';
import Text from '@components/common/Text/Text';
import { S_DEVICE } from '@components/lpc/Constants';
import { getRoute, ROUTE } from '@services/http/Route';

import css from './ContestBanner.module.scss';

type ContestBannerProps = {
    onHideContestBanner: () => void;
    activeContestBanner: any;
};
const ContestBanner = ({ onHideContestBanner, activeContestBanner }: ContestBannerProps) => {
    const router = useRouter();
    const { width } = useWindowSize();
    // const hash: string = crypto.getRandomValues(new Uint32Array(1)).toString();
    const { t } = useTranslateLpc('common');

    return (
        <div className={css.contest__container}>
            {activeContestBanner?.authorizationBO && (
                <Text variant={'mini_label'} color={'txt-primary'} customClass={css.contest__banner__admin_header}>
                    <img
                        className={css.contest__banner__admin_header__icon}
                        src="/front-static/icons/display/alert-circle-outline-primary.svg"
                        alt="alert"
                    />
                    <span
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(t('competition-game.admin-banner')) }}
                    />
                </Text>
            )}

            <div
                onClick={() => {
                    router.push(
                        `${getRoute(
                            ROUTE.LPC.CONTEST + activeContestBanner.contestUniq,
                        )}?utm_source=site&utm_medium=bandeauhp&utm_campaign=avecousansenfants&utm_id=avecousansenfants`,
                    );
                }}
                className={css.contest__banner}
                style={{ bottom: activeContestBanner.authorizationBO ? (width > S_DEVICE ? '55px' : '50px') : null }}
            >
                <img
                    src={
                        process.env.BACKEND_BASE_URL +
                        (width > S_DEVICE ? activeContestBanner.bannerImgUrl : activeContestBanner.mobileBannerImgUrl)
                    }
                    alt="banner"
                />
                <span
                    onClick={(event) => {
                        onHideContestBanner();
                        event.stopPropagation();
                    }}
                    className={`${css.contest__banner__close}`}
                />
            </div>
        </div>
    );
};

export default ContestBanner;
