import React, { useEffect, useState } from 'react';

import Image from 'next/image';
import { useRouter } from 'next/router';

import Button from '@components/common/Button/Button';
import useTranslateLpc from '@components/common/hooks/useTranslateLpc';
import useWindowSize from '@components/common/hooks/useWindowSize';
import IconCheckmark from '@components/common/IconCheckmark/IconCheckmark';
import ConfirmModal from '@components/common/Modals/ConfirmModal/ConfirmModal';
import Text from '@components/common/Text/Text';
import { M_DEVICE } from '@components/lpc/Constants';
import TrustBox, { TemplatesTypes } from '@components/lpc/TrusBox/TrustBox';
import { getRoute, ROUTE } from '@services/http/Route';
import { clearItemFromStorage, setItemToStorage } from '@services/utils/StorageService';
import { getCategoryIconSrc } from '@services/utils/UserService';

import css from './HeroBanner.module.scss';

const HeroBanner = () => {
    const { t } = useTranslateLpc(['lpc-home', 'common', 'lpc-pot-mpp-spending', 'lpc-create-pot']);
    const router = useRouter();
    const { width } = useWindowSize();
    const [selectedCategory, setSelectedCategory] = useState<string>('BIRTHDAY');
    const [listRedirectionModalVisible, setListRedirectionModalVisible] = useState<boolean>(false);

    useEffect(() => {
        setItemToStorage('session', 'temporaryCategory', selectedCategory);
    }, [selectedCategory]);

    useEffect(() => {
        const isList = selectedCategory === 'WEDDING_PACS_GIFT' || selectedCategory === 'BIRTH_GIFT';

        if (isList) {
            setListRedirectionModalVisible(true);
        }
    }, [selectedCategory]);

    return (
        <div className={css.landing}>
            <ConfirmModal
                onHide={() => {
                    setListRedirectionModalVisible(false);
                    setSelectedCategory('BIRTHDAY');
                }}
                onConfirm={() => router.push(ROUTE.WEDDING_LIST)}
                title={t(`modal.${selectedCategory}.title`)}
                confirmLabel={t(`modal.${selectedCategory}.redirect-confirm`)}
                cancelLabel={t(`modal.${selectedCategory}.redirect-cancel`)}
                isVisible={listRedirectionModalVisible}
                iconComponent={<IconCheckmark variant={'list'} />}
                customIcon={css.modal}
                customRootClass={css.modal__overlay}
            />
            <h1>
                {t('landing.main-title')}
                <span className={css.landing__sub_title}>{t('landing.main-sub-title')}</span>
            </h1>
            <Text variant={'body_00'} color={'txt-primary'} customClass={css.landing__text}>
                {t('landing.main-desc')}
            </Text>

            <div className={css.landing__main}>
                <Button
                    variant={'primary'}
                    customClass={css.landing__main__primary}
                    onClick={() => {
                        clearItemFromStorage('session', 'temporaryPot');
                        setItemToStorage('local', 'createPotCategory', selectedCategory);
                        router.push(getRoute(ROUTE.LPC.POT.CREATE_POT)).then();
                    }}
                >
                    {t('landing.create')}
                </Button>
                <TrustBox
                    dataTemplateId={TemplatesTypes.MICRO_COMBO}
                    width={'190px'}
                    height={'100px'}
                    containerWidth={width > M_DEVICE ? '195px' : null}
                />
            </div>
            <Image
                width={16}
                height={16}
                className={css.black}
                src="/front-static/images/home/sphere-black.png"
                alt="sphere"
            />
            <Image
                width={16}
                height={16}
                className={css.pink}
                src="/front-static/images/home/sphere-pink.png"
                alt="sphere"
            />
            <Image
                width={16}
                height={16}
                className={css.yellow_small}
                src="/front-static/images/home/sphere-yellow.png"
                alt="sphere"
            />
            <Image
                width={16}
                height={16}
                className={css.yellow_big}
                src="/front-static/images/home/sphere-yellow.png"
                alt="sphere"
            />
            <Image
                width={16}
                height={16}
                className={`${css.category} ${css.category__left}`}
                src={getCategoryIconSrc(selectedCategory)}
                alt={selectedCategory}
            />
            <Image
                width={16}
                height={16}
                className={`${css.category} ${css.category__right}`}
                src={getCategoryIconSrc(selectedCategory)}
                alt={selectedCategory}
            />
        </div>
    );
};
export default HeroBanner;
